/**
 * File:
 * Project db-portal.dev
 * Created by tkwoka on 20.11.17.
 * Author: Tomasz Kwoka [UDC]
 * Author Contact: tomek@uslugidlaciebie.com
 */

body {
    font-family: $baseFont;
    background-color: #fff;
}

* {
    font-weight: 400;
    outline: none !important;

    &:focus {
        outline: none !important;
    }
}

a {
    color: $color_href;
    transition: all 0.2s ease-in-out;

    &:hover {
        text-decoration: none;
        color: $color_red;
    }

    &.no-hover {
        color: $color_href;
        cursor: default;

        &:hover {
            color: $color_href !important;
        }
    }
}

#main-navbar {
    background: #fff;
    margin-bottom: 10px;

    .navbar-brand {
        height: auto;
        border-bottom: 1px solid #DDDDDD;

        img {
            width: 45px;
        }
    }
}

.main-content {
    p {
        font-size: 15px;
        line-height: 22px;
    }

    .header-lang-title {
        display: flex;
        align-items: center;

        img {
            margin-right: 15px;
        }
    }

    .about-text {
        min-height: 100px;
        padding: 10px 0;
    }
}

.footer-inner-wrapper {
    border-top: 1px solid #DDDDDD;
    padding: 15px 0;
    font-size: 12px;
    color: #999999;
}