/**
 * File:
 * Project db-portal.dev
 * Created by tkwoka on 06.12.17.
 * Author: Tomasz Kwoka [UDC]
 * Author Contact: tomek@uslugidlaciebie.com
 */

.pickup-card-wrapper {
    border: 1px solid #ddd;
    padding: 20px 10px;

    #card-container {
        width: 100%;
    }
}

.wish-item {
    display: block;
    padding: 5px;
}