/*==================================================
COLORS
====================================================*/
$color_base: #333333;
$color_red: #ed2914;
$color_gray: #878c96;
$color_gray_light: #dedede;
$color_gray_ultra_light: #f5f5f5;
$color_blue_dark: #010066;
$color_blue: #377b96;

$color_href: #000;
$color_hover: #010066;
$color_focus: #000;

$baseFont: 'DBSans';
