/**
 * File:
 * Project db-portal.dev
 * Created by tkwoka on 27.11.17.
 * Author: Tomasz Kwoka [UDC]
 * Author Contact: tomek@uslugidlaciebie.com
 */

.no-margin {
    margin: 0;
}

.no-m-t {
    margin-top: 0;
}

.no-m-r {
    margin-right: 0;
}

.no-m-b {
    margin-bottom: 0;
}

.no-m-l {
    margin-left: 0;
}

.no-padding {
    padding: 0;
}

.no-p-t {
    padding-top: 0;
}

.no-p-r {
    padding-right: 0;
}

.no-p-b {
    padding-bottom: 0;
}

.no-p-l {
    padding-left: 0;
}

.m-t-lg {
    margin-top: 30px;
}

.m-t-md {
    margin-top: 20px;
}

.m-t-sm {
    margin-top: 10px;
}

.m-t-xs {
    margin-top: 5px;
}

.m-b-lg {
    margin-bottom: 30px;
}

.m-b-md {
    margin-bottom: 20px;
}

.m-b-sm {
    margin-bottom: 10px;
}

.m-b-xs {
    margin-bottom: 5px;
}

.m-t-lg {
    margin-top: 30px;
}

.m-t-md {
    margin-top: 20px;
}

.m-t-sm {
    margin-top: 10px;
}

.m-t-xs {
    margin-top: 5px;
}

.m-b-lg {
    margin-bottom: 30px;
}

.m-b-md {
    margin-bottom: 20px;
}

.m-b-sm {
    margin-bottom: 10px;
}

.m-b-xs {
    margin-bottom: 5px;
}

.m-r-lg {
    margin-right: 30px;
}

.m-r-md {
    margin-right: 20px;
}

.m-r-sm {
    margin-right: 10px;
}

.m-r-xs {
    margin-right: 5px;
}

.m-l-lg {
    margin-left: 30px;
}

.m-l-md {
    margin-left: 20px;
}

.m-l-sm {
    margin-left: 10px;
}

.m-l-xs {
    margin-left: 5px;
}

.m-lr-a {
    margin-left: auto;
    margin-right: auto;
}

.p-t-lg {
    padding-top: 30px;
}

.p-t-md {
    padding-top: 20px;
}

.p-t-sm {
    padding-top: 10px;
}

.p-t-xs {
    padding-top: 5px;
}

.p-b-lg {
    padding-bottom: 30px;
}

.p-b-md {
    padding-bottom: 20px;
}

.p-b-sm {
    padding-bottom: 10px;
}

.p-b-xs {
    padding-bottom: 5px;
}

.p-r-lg {
    padding-right: 30px;
}

.p-r-md {
    padding-right: 20px;
}

.p-r-sm {
    padding-right: 10px;
}

.p-r-xs {
    padding-right: 5px;
}

.p-l-lg {
    padding-left: 30px;
}

.p-l-md {
    padding-left: 20px;
}

.p-l-0 {
    padding-left: 0;
}

.p-r-0 {
    padding-right: 0;
}

.p-l-sm {
    padding-left: 10px;
}

.p-l-xs {
    padding-left: 5px;
}

.br-lg {
    border-radius: 30px;
}

.br-md {
    border-radius: 20px;
}

.br-sm {
    border-radius: 10px;
}

.br-xs {
    border-radius: 5px;
}

.align-baseline {
    vertical-align: baseline !important;
}

.align-top {
    vertical-align: top !important;
}

.align-middle, .vertical-middle {
    vertical-align: middle !important;
}

.align-bottom {
    vertical-align: bottom !important;
}

.align-text-top {
    vertical-align: text-top !important;
}

.align-text-bottom {
    vertical-align: text-bottom !important;
}

.flex, .d-flex {
    display: flex;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-flow-row {
    flex-flow: row wrap;
}

.align-items-center {
    align-items: center;
}

.flex-align-center {
    align-items: center;
}

.align-self-center {
    align-self: center;
}

.justify-content-center {
    justify-content: center;
}

.flex-center {
    justify-content: center;
}