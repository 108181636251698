/**
 * File:
 * Project db-portal.dev
 * Created by tkwoka on 10.01.18.
 *
 * Author: [UDC] Tomasz Kwoka
 * Author Contact: tomek@uslugidlaciebie.com
 * Author URI: http://uslugidlaciebie.com
 */


/** ========   SELECT2  ======= */
.select2 {

    &.select2-container {

        .select2-selection.select2-selection--single {
            height: 34px;
            border: 1px solid #ccc;

            .select2-selection__rendered {
                line-height: 34px;
            }

            .select2-selection__arrow {
                height: 34px;
            }
        }
    }
}