/**
 * File:
 * Project db-portal.dev
 * Created by tkwoka on 27.11.17.
 * Author: Tomasz Kwoka [UDC]
 * Author Contact: tomek@uslugidlaciebie.com
 */

.navbar {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

a {
    &.thumbnail {
        &:hover {
            text-decoration: none;
        }
    }
}

.popover {
    background: $color_red;
    font-size: 11px;
    color: #fff;
    padding: 0;
    border: 1px solid $color_red;

    &.top {
        margin-top: -4px;
    }

    .arrow {
        bottom: -11px !important;
        margin-left: -5px !important;
        border-width: 5px !important;

        &:after {
            border-width: 10px;
            border-top-color: $color_red !important;
        }
    }
}